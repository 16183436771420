<template>
  <div>
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getFence, delFence, updFence } from '@/api/project'
export default {
  name: 'SystemAppWx',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '名称',
          type: 'input',
          options: [],
          key: 'fenceName',
        },
        {
          name: '类型',
          type: 'picker',
          options: [],
          key: 'type',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '排序',
          width: '270',
        },
        {
          prop: 'p_id',
          label: '名称',
          width: '270',
        },
        {
          prop: 'status',
          label: '类型',
          width: '270',
          type: 'switch',
        },
        {
          prop: 'create_at',
          label: '状态',
          width: '270',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '270',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeFence',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        name: '',
        p_id: '',
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getFence(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.buttons[1].name = item.status === 1 ? '禁用' : '启用'
          item.buttons[1].type = item.status === 1 ? 'warning' : 'success'

          item.tags = {
            type: item.status === 1 ? 'success' : 'danger',
            name: item.status === 1 ? '已启用' : '已禁用',
          }
        })
        this.dataArr = result.data
      })
    },

    // 修改围栏
    editFence: function({ id, status }) {
      const data = {
        id,
        status: status === 1 ? 0 : 1,
      }
      updFence(data).then(res => {
        console.log(res, '修改成功')
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除围栏
    removeFence: function({ id }) {
      delFence(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { fenceName, fenceProject } = data
      this.formData.name = fenceName
      this.formData.p_id = fenceProject
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
